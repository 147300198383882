import React, { forwardRef } from 'react';
import {
    Dialog,
    DialogContent,
    Box,
    useMediaQuery,
    ButtonBase,
    CircularProgress,
    Typography,
    Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Cancel } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles({
    paper: {
        width: `95% !important`,
        marginLeft: 0,
        marginRight: 0,
        overflowY: 'visible',
    },
    limitSize: {
        maxWidth: `410px !important`,
    },
    paperWidthFalse: {
        maxWidth: '100%',
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: 0,
    },
    identityPayment: {
        marginBottom: 16,
        textAlign: 'center',
    },
    logoPayment: {
        width: 150,
        height: 150,
    },
    amountPayment: {
        fontWeight: 'bold',
        padding: '10px',
    },
});

const ReDialog = forwardRef(
    (
        {
            showModal,
            closeModal,
            loading,
            valid,
            message,
            sessionAdyen
        },
        ref,
    ) => {
        const classes = useStyles();
        const bigSizeScreen = useMediaQuery('(min-width:600px)');

        // open = jika ada props useForwardRef, pakai default yaitu true
        // jika tidak ada gunakan local state biar berdiri sendiri
        // onClose = jika ada props useForwardRef, gunakan closeModal
        // jika tidak update local state
        return (
            <Dialog
                open={showModal}
                onClose={() => closeModal()}
                scroll={'body'}
                maxWidth={false}
                fullWidth
                classes={{
                    paper: `${classes.paper} ${bigSizeScreen && classes.limitSize}`,
                    paperWidthFalse: classes.paperWidthFalse,
                }}>
                <DialogContent
                    style={{
                        paddingTop: 36,
                        paddingBottom: 36
                    }}>
                    {valid ?
                        <>
                            <div style={{ textAlign: "center" }}>
                                {loading && <CircularProgress />}
                            </div>
                            <Box className={classes.identityPayment}>
                                <img className={classes.logoPayment} alt="logo" src={sessionAdyen.logo} />
                                <div>
                                    {sessionAdyen.outletName}
                                </div>
                                <Typography variant="h4" className={classes.amountPayment}>
                                    {sessionAdyen.amount}
                                </Typography>
                            </Box>
                            <Box id='dropin-container'></Box>

                        </>
                        : <Alert variant="filled" severity="error">{message}</Alert>
                    }
                    <Button
                        style={{
                            color: sessionAdyen.color.secondaryFontColor,
                            backgroundColor: sessionAdyen.color.primaryThemeColor,
                            width: '100%',
                            marginTop: 8,
                            height: 48,
                            fontSize: 16
                        }}
                        onClick={() => closeModal()}>
                            {valid ? 'Cancel' : 'Close'}
                    </Button>
                </DialogContent>
            </Dialog>
        );
    },
);

export default ReDialog;
