import { useEffect, useState } from 'react'
import useCheckUuid from './useCheckUuid';

async function fetchWithUUID(uuid, url) {
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    };

    const body = {
        "target": "GET_TARGET",
        "env": import.meta.env.MODE === "STAGING" ? "TEST" : import.meta.env.MODE,
        "uuid": uuid
    };

    const options = {
        method: 'POST',
        cache: 'no-cache',
        headers: headers,
        body: JSON.stringify(body),
    };

    try {
        const response = await fetch(url, options);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error('Fetch error:', error);
        return { error: error.message };
    }
}

function useCheckServerUrl() {
    const getSearchParam = window.location.href.split('?')[1];
    const [searchParam, setSearchParam] = useState(getSearchParam)
    const [localState, setLocalState] = useState({
        uuid: null,
        server: 'A',
        error: false,
        isLoading: false,
    })
    const urlDomainServer = import.meta.env[`VITE_REACT_APP_DOMAIN_SERVER`];
    const [uuid, checkURLUuid] = useCheckUuid()


    const getServerUrl = () => {
        const serverUrl = new URLSearchParams(searchParam).get("server");
        const serverData = serverUrl ? decodeURIComponent(serverUrl) : null;
        return serverData
    }

    const serverDecode = getServerUrl()


    const fetchServerData = async () => {
        setLocalState(prevState => ({ ...prevState, isLoading: true }));
        try {
            const data = await fetchWithUUID(uuid, urlDomainServer);
            setLocalState(prevState => ({ ...prevState, server: data, isLoading: false }));
        } catch (error) {
            setLocalState(prevState => ({ ...prevState, error: true, isLoading: false }));
        }
    };

    useEffect(() => {
        checkURLUuid()
        if (serverDecode && serverDecode.trim().length === 0 && uuid) {
            fetchServerData();
        } else {
            setLocalState(prevState => ({ ...prevState, server: serverDecode }));
        }
    }, [uuid, serverDecode]);



    return localState;
};

export default useCheckServerUrl
