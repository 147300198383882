// Suggested code may be subject to a license. Learn more: ~LicenseLog:3476863093.
import React from 'react';
import styled from 'styled-components';

const ErrorPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const ServerErrorPage = ({ message }) => {
  return (
    <ErrorPageContainer>
      <div>
        <h1>Failed To fetch from server</h1>
        <p>{message}</p>
      </div>
    </ErrorPageContainer>
  );
};

export default ServerErrorPage;
