import { useState } from 'react'

function useCheckUuid() {
    const getSearchParam = window.location.href.split('?')[1];
    //NOTE: Agar getSplashScreen tidak null atau kosong getSearchParam harus disimmpan dalam state atau localStorage
    const [uuid, setUuid] = useState()
    const checkURLUuid = () => {
        let result = uuid;
        if (getSearchParam && !uuid) {
            const newUrl = getSearchParam.replace(/&amp;/g, '&');
            const urlUUID = new URLSearchParams(`?${newUrl}`).get('uuid');
            result = urlUUID;
            setUuid(result)
        }
        return result;
    };

    return [uuid, checkURLUuid]
}

export default useCheckUuid