import React, { useEffect, useState } from 'react';
import defaultSplash from '@assets/images/default_splash.png';
import BrowserIframe from './BrowserIframe';
import useCheckServerUrl from './hooks/useHitAPI';
import ServerErrorPage from "./pages/ServerErrorPage"
const SplashScreen = () => {
    const getSearchParam = window.location.href.split('?')[1];
    const [searchParam, setSearchParam] = useState(getSearchParam)
    // const [localSearchParam] = useState(() => localStorage.getItem("path"))
    //inisiasi pertamakali buka aplikasi
    const [showSplash, setShowSplash] = useState(false);
    const [splashState, setSplashState] = useState({})
    // const SERVER_A = "A"

    const { server, error, isLoading } = useCheckServerUrl()


    const getSplashScreen = () => {
        const splashFromURL = new URLSearchParams(searchParam).get("splash");

        const splashData = decodeURIComponent(splashFromURL);
        if (!splashData) {
            return defaultSplash
        }
        return splashData
    }

    const clearStorage = () => {
        localStorage.clear()
    }

    useEffect(() => {
        if (getSearchParam) {
            clearStorage()
        }
    }, [getSearchParam])


    useEffect(() => {
        // if (!server) {
        //     return
        // }
        setSplashState({
            domain: import.meta.env[`VITE_REACT_APP_DOMAIN_${import.meta.env.MODE}_${server}`],
            serverURL: import.meta.env[`VITE_REACT_APP_DOMAIN_${import.meta.env.MODE}_IFRAME`],
            path: '/?' + getSearchParam,
            server: server
        })

        getSplashScreen("splash")

        //NOTE: Digunakan untuk menghilangkan splash screen saat refresh
        const hasSplashScreenBefore = localStorage.getItem("splashShown")
        if (!hasSplashScreenBefore) {
            setShowSplash(true);
            localStorage.setItem('splashShown', true);
        }

        if (server) {
            localStorage.setItem(
                "domain_fe",
                import.meta.env[`VITE_REACT_APP_DOMAIN_${import.meta.env.MODE}_${server}`],
            );
        }

    }, [server]);
    
    return (
        error ? <ServerErrorPage message={"There is an issue on the server"} /> : (
            <div>
                {showSplash &&
                    <img alt="placeholder" className='SplashScreen' style={{ maxWidth: 450, maxHeight: '100vh', height: '100%', width: "100%" }} src={getSplashScreen() + "?quality=5"} />
                }
                <BrowserIframe splashState={splashState} setShowSplash={(e) => setShowSplash(e)} isLoading={isLoading}/>
            </div>

        )

    );
};

export default SplashScreen;
